







import { Vue, Component, Emit } from 'vue-property-decorator';
import { ConfirmOrderAccountInfo } from '@/utils/jump';
@Component({
  name: ''
})
export default class extends Vue {
  @Emit('get-info')
  setInfo() {
    return this.rechargeAccount.map(item => item.value).join('=');
  }

  /* 虚拟商品充值账号 */
  rechargeAccount: ConfirmOrderAccountInfo[] = [];
  created() {
    this.rechargeAccount.map(item => item.value).join('=');
    this.rechargeAccount = JSON.parse(this.$route.query.account as any) as ConfirmOrderAccountInfo[];
    this.setInfo();
  }
}
