
























import { AddressItem, getDefaultAddress } from '@/api/user/address';
import router from '@/router';
import { getBuyAddress, removeBuyAddress, setBuyAddress } from '@/utils/storage';
import { AddressOptionsEnum } from '@/views/address/address-list.vue';
import { isEmpty } from 'lodash';
import { Vue, Component, Emit } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  addressInfo = {} as AddressItem;

  @Emit('get-info')
  async getDefaultAddress() {
    const address = getBuyAddress();
    if (address) {
      this.addressInfo = address;
    } else {
      const { payload } = await getDefaultAddress();
      setBuyAddress(payload || {});
      this.addressInfo = payload || {};
    }
    if (!isEmpty(this.addressInfo)) {
      return this.addressInfo.addressId;
    }
  }

  /* 去新增地址 */
  goToAddressAdd() {
    router.push({
      name: 'AddressEdit',
      query: {
        type: AddressOptionsEnum.CREATE_ORDER_SELECT
      }
    });
  }

  /* 去地址列表选择 */
  goToAddressList() {
    router.push({
      name: 'AddressList',
      query: {
        type: AddressOptionsEnum.CREATE_ORDER_SELECT
      }
    });
  }

  beforeDestroy() {
    removeBuyAddress();
  }

  created() {
    this.getDefaultAddress();
  }
}
