























import { getSkuIdList } from '@/api/product';
import { GetPromiseReturn } from '@/api/type/tool';
import { Vue, Component, Prop } from 'vue-property-decorator';
type IData = GetPromiseReturn<typeof getSkuIdList>['payload'][number];
@Component({
  name: ''
})
export default class extends Vue {
  @Prop()
  innerData!: IData;
  @Prop()
  amount!: number;
}
